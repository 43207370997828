const _ = require('lodash')

const MEETING_LINK = 'https://savvycal.com/caplena/demo'
const ADDITIONAL_COLUMNS = ['topics', 'sentiment', 'reviewed', 'changed']
const ADDITIONAL_COLUMNS_CODES = ['codes', 'sentiment', 'reviewed', 'changed']
const QUESTION_CATEGORY_CUSTOMER_SATISFACTION = 'CS'
const QUESTION_CATEGORY_EMPLOYEE_FEEDBACK = 'EF'
const QUESTION_CATEGORY_BRAND_PERCEPTION = 'BR'
const QUESTION_CATEGORY_PRODUCT_PERCEPTION = 'PP'
const QUESTION_CATEGORY_EVENT_EVALUATION = 'EV'
const QUESTION_CATEGORY_LIST = 'LA'
const QUESTION_CATEGORY_OTHER = 'OT'
const QUESTION_CATEGORY_NONE = 'NO'

const PROJECT_NAME_DEMO_OPEN = 'Demo [Fully-Open] - Telecom NPS'
const PROJECT_NAME_DEMO_LIST = 'Demo [Semi-Open] - Banks unaided awareness'
const DASHBOARD_NAME_DEMO = 'Demo - Telecom NPS'
const CHART_NAME_DEMO = 'Demo - Telecom'

const QUESTION_CATEGORIES = [
  QUESTION_CATEGORY_CUSTOMER_SATISFACTION,
  QUESTION_CATEGORY_EMPLOYEE_FEEDBACK,
  QUESTION_CATEGORY_BRAND_PERCEPTION,
  QUESTION_CATEGORY_PRODUCT_PERCEPTION,
  QUESTION_CATEGORY_EVENT_EVALUATION,
  QUESTION_CATEGORY_LIST,
  QUESTION_CATEGORY_OTHER,
  QUESTION_CATEGORY_NONE
]

const COLUMN_TYPE_QUESTION = 'QUESTION'
const COLUMN_TYPE_AUXILIARY = 'GENERIC_AUXILIARY'
const COLUMN_TYPE_DATE = 'DATE'
const COLUMN_TYPE_NPS_10 = 'NPS_10'
const COLUMN_TYPE_NPS_100 = 'NPS_100'
const COLUMN_TYPE_LANGUAGE = 'SOURCE_LANGUAGE'
const COLUMN_TYPE_STAR = 'STAR_5'
const COLUMN_TYPE_NULL = 'null'

const TEXT_TO_ANALYZE_CATEGORY_CUSTOMER_SATISFACTION = 'customer_satisfaction'
const TEXT_TO_ANALYZE_CATEGORY_EMPLOYEE_FEEDBACK = 'employee_feedback'
const TEXT_TO_ANALYZE_CATEGORY_BRAND_PERCEPTION = 'brand_perception'
const TEXT_TO_ANALYZE_CATEGORY_PRODUCT_PERCEPTION = 'product_perception'
const TEXT_TO_ANALYZE_CATEGORY_EVENT_EVALUATION = 'event_evaluation'
const TEXT_TO_ANALYZE_CATEGORY_LIST = 'list_answers'
const TEXT_TO_ANALYZE_CATEGORY_OTHER = 'other'
const TEXT_TO_ANALYZE_CATEGORY_NONE = null

const TEXT_TO_ANALYZE_CATEGORIES = [
  TEXT_TO_ANALYZE_CATEGORY_CUSTOMER_SATISFACTION,
  TEXT_TO_ANALYZE_CATEGORY_EMPLOYEE_FEEDBACK,
  TEXT_TO_ANALYZE_CATEGORY_BRAND_PERCEPTION,
  TEXT_TO_ANALYZE_CATEGORY_PRODUCT_PERCEPTION,
  TEXT_TO_ANALYZE_CATEGORY_EVENT_EVALUATION,
  TEXT_TO_ANALYZE_CATEGORY_LIST,
  TEXT_TO_ANALYZE_CATEGORY_OTHER,
  TEXT_TO_ANALYZE_CATEGORY_NONE
]

const SELECTABLE_COLUMN_TYPES = [
  COLUMN_TYPE_QUESTION,
  COLUMN_TYPE_DATE,
  COLUMN_TYPE_LANGUAGE,
  COLUMN_TYPE_NPS_10,
  COLUMN_TYPE_NPS_100,
  COLUMN_TYPE_STAR,
  COLUMN_TYPE_AUXILIARY
  // COLUMN_TYPE_NULL
]

const DEFAULT_INTEGRATION_LINK = {
  external_id: '',
  sync_status: '',
  progress: 0
}

const DEFAULT_ROW_LIMIT = 50

const MIN_TOPIC_LABEL_LEN = 2
const MAX_TOPIC_LABEL_LEN = 50
const MIN_TOPIC_CATEGORY_LEN = 2
const MAX_TOPIC_CATEGORY_LEN = 30
const MAX_TOPIC_DESCRIPTION_LEN = 255
const MAX_TOPIC_KEYWORD_LEN = 30
const MAX_ADD_KEYWORDS = 5

const TOPIC_ATTITUDES = ['sentiment_neutral', 'sentiment_positive', 'sentiment_negative']
const TOPIC_ATTITUDE_TEMPLATE = { code: -1, label: '' }
const TOPIC_TEMPLATE = {
  id: '',
  label: '',
  category: '',
  color: '',
  description: '',
  keywords: [],

  sentiment_enabled: false,
  sentiment_neutral: _.cloneDeep(TOPIC_ATTITUDE_TEMPLATE),
  sentiment_positive: _.cloneDeep(TOPIC_ATTITUDE_TEMPLATE),
  sentiment_negative: _.cloneDeep(TOPIC_ATTITUDE_TEMPLATE)
}

const TOPIC_ADHOC_ID_PREFIX = 'cd_adhoc_'

const MIN_PROJECT_NAME_LEN = 5
const MIN_QUESTION_NAME_LEN = 1
const MAX_QUESTION_NAME_LEN = 50
const MIN_QUESTION_DESCRIPTION_LEN = 5

const MAX_EXCEL_EXPORT_ROWS = 50000

const UPLOAD_ENCODING_OPTIONS = ['Auto', 'UTF8', 'Latin_1', 'Windows-1252']
const UPLOAD_ANSWERS_TRANSLATE_BILL_THRESHOLD = 2500

// training time estimation: tpu_boot_time + coder_overhead + (nanswers * ncodes) * nepochs / samples_per_sec
const TRAINING_TIME_ESTIMATOR_BERT = (nanswers, ncodes) => 225 + 155 + (nanswers * ncodes) * 5 / 1000

const CHART_TYPE_BAR = 'bar'
const CHART_TYPE_SENTIMENT_BAR = 'sentiment-bar'
const CHART_TYPE_LINE_PIE = 'line-pie'
const CHART_TYPE_DATE_LINE_PIE = 'date-line-pie'
const CHART_TYPE_ROWS_COUNT_BAR = 'rows-count-bar'
const CHART_TYPE_TREEMAP = 'treemap'
const CHART_TYPE_GRAPH = 'graph'
const CHART_TYPE_SCORE = 'nps'
const CHART_TYPE_DRIVER_SCATTER = 'driver-scatter'
const CHART_TYPE_DRIVER_HEAT = 'driver-heat'
const CHART_TYPE_PSEUDO_TEXT = 'p-text'
const CHART_TYPE_PSEUDO_VERBATIMS = 'p-verb'
const CHART_TYPE_DYNAMIC_BAR = 'dynamic-bar'

const VERBATIM_PAGE_SIZE = 25

const CHART_TYPES = [
  CHART_TYPE_BAR,
  CHART_TYPE_SENTIMENT_BAR,
  CHART_TYPE_LINE_PIE,
  CHART_TYPE_DATE_LINE_PIE,
  CHART_TYPE_ROWS_COUNT_BAR,
  CHART_TYPE_TREEMAP,
  CHART_TYPE_GRAPH,
  CHART_TYPE_SCORE,
  CHART_TYPE_PSEUDO_TEXT,
  CHART_TYPE_PSEUDO_VERBATIMS
]

const CHART_UNIQUE_PSEUDO_TEXT = 'p-text'
const CHART_UNIQUE_PSEUDO_VERBATIMS = 'p-verb'
const CHART_UNIQUE_BAR = 'bar'
const CHART_UNIQUE_LINE_PIE = 'line-pie'
const CHART_UNIQUE_DATE_LINE_PIE = 'date-line-pie'
const CHART_UNIQUE_ROWS_COUNT_BAR = 'rows-count-bar'
const CHART_UNIQUE_TREEMAP = 'treemap'
const CHART_UNIQUE_GRAPH = 'graph'
const CHART_UNIQUE_SCORE = 'nps'
const CHART_UNIQUE_DRIVER_SCATTER = 'driver-scatter'
const CHART_UNIQUE_DRIVER_HEAT = 'driver-heat'
const CHART_UNIQUE_SENTIMENT = 'sentiment'
const CHART_UNIQUE_SENTIMENT_BAR = 'sentiment-bar'
const CHART_UNIQUE_DYNAMIC_BAR = 'dynamic-bar'

const CHART_UNIQUE_NAMES = [
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_DRIVER_SCATTER,
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_SENTIMENT_BAR
]

const CHART_TYPES_REAL = [
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH
]

const CODE_CHARTS = [
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE, // @sasha delete it
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_DYNAMIC_BAR
]

const SCORE_CHARTS = [
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_DRIVER_SCATTER
]

const OVERVIEW_CHARTS = [
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_ROWS_COUNT_BAR
]

const CHART_TYPES_TO_API_ENUM = {
  [CHART_UNIQUE_BAR]: 'BAR',
  [CHART_UNIQUE_LINE_PIE]: 'LINEP',
  [CHART_UNIQUE_DATE_LINE_PIE]: 'DLINP',
  [CHART_UNIQUE_ROWS_COUNT_BAR]: 'DCNT',
  [CHART_UNIQUE_TREEMAP]: 'TREE',
  [CHART_UNIQUE_GRAPH]: 'GRAPH',
  [CHART_UNIQUE_SCORE]: 'NPS',
  [CHART_UNIQUE_DRIVER_SCATTER]: 'DR_SC',
  [CHART_UNIQUE_DRIVER_HEAT]: 'DR_HT',
  [CHART_TYPE_PSEUDO_TEXT]: 'P_TXT',
  [CHART_TYPE_PSEUDO_VERBATIMS]: 'P_VRB',
  [CHART_UNIQUE_SENTIMENT]: 'SENT',
  [CHART_UNIQUE_SENTIMENT_BAR]: 'S_BAR',
  [CHART_UNIQUE_DYNAMIC_BAR]: 'DYBAR'
}

const MAX_DATASET_COUNT = 25

const API_ENUM_TO_CHART_TYPES = Object.entries(CHART_TYPES_TO_API_ENUM).reduce((sum, [key, value]) => ({
  ...sum,
  [value]: key
}), {})

const INTEGRATION_CONSISTENCY_LIMIT_COUNT = 50000

const SCORING_TYPE_NPS = 'NPS'
const SCORING_TYPE_CSAT = 'CSAT'
const SCORING_TYPE_AVERAGE = 'AVG'
const SCORING_TYPE_SENTIMENT = 'Sentiment'

const SATISFACTION_SCORING_TYPES = [
  SCORING_TYPE_NPS,
  SCORING_TYPE_CSAT,
  SCORING_TYPE_AVERAGE
]
const UNIQUE_SCORING_TYPES = [
  SCORING_TYPE_SENTIMENT
]
const UNIQUE_CHARTS_FOR_UNIQUE_SCORING_TYPES = {
  [CHART_UNIQUE_SENTIMENT]: SCORING_TYPE_SENTIMENT
}

const DS_SETTINGS_TEMPLATE = {
  weighting_column: null,
  weighting_normalize: false,
  name: '',
  driver_target_column: null,
  scoring_type: null,
  scoring_column: null,
  color: { override: false, value: 'rgba(255,0,0,1.0)' }
}

const DS_FILTERS_TEMPLATE = []

const USER_SETTINGS_TEMPLATE = {
  opt_out_tracking: false,
  dummy_strings: ['', '', '']
}

const PLAN_FREE = 10
const PLAN_TRIAL = 99
const PLAN_ONEOFF = 100
const PLANS_ACTIVE = [110, 120, 134] // freelance, business, enterprise

// The features which either
// * all plans have (and thus will only be displayed for the first one)
// or
// * are defined on the database for the plans
const STANDARD_PLAN_FEATURES = [
  'coding',
  'codebook_generator',
  'basic_filetypes',
  'advanced_filetypes',
  'google_translate',
  'deepl',
  'api_access',
  'visualizations_access',
  'visualizations_whitelabel',
  'max_accounts',
  'allowed_roles',
  'custom_roles',
  'cc_payment',
  'invoice_payment'
]

// The features which are not defined in the database for the plans, but are different for them
const CUSTOM_PLAN_FEATURES = {
  110: ['codebook_templates', 'standard_support', 'data_uploads_optional', 'trainings_optional', '2fa'],
  120: ['object_level_permissions', 'priority_support', { key: 'trainings_included', value: 1 }],
  134: [
    'corporate_colors',
    'feature_prioritization',
    'anonymization',
    { key: 'data_uploads', value: 5 },
    'premium_support',
    { key: 'trainings_included', value: 4 },
    'custom_agreements', 'sla'
  ]
}

const ONE_OFF_STEPS = [500, 1000, 2500, 5000, 10000, 20000, 30000, 50000, 75000, 100000, 150000, 200000, 300000, 500000, 1000000]

const BADGES = {
  architect: { maxLevel: 3 },
  polymath: { maxLevel: 2 },
  philantropist: { maxLevel: 2 },
  professor: { maxLevel: 3 }
}

// Terms that we wil look for in the auxiliary column values, that indicate a CSAT column
// (if all the elements are matched to a set)
const CSAT_TERMS = [
  new Set(['very unsatisfied', 'unsatisfied', 'neutral', 'satisfied', 'very satisfied']),
  new Set(['very bad', 'poor', 'average', 'good', 'excellent'])
]

// Terms that we wil look for in the auxiliary column values, that indicate a NPS column
// (if all the elements are matched to a set)
const NPS_TERMS = [
  // String values 0-10 and the ebay-like strings
  new Set([...Array(11).keys()].map(String).concat(['not at all likely 0', 'not at all likely  0', 'extremely likely 10', 'extremely likely  10']))
]

// Terms we will look for in the auxiliary column names, that indicate a NPS / CSAT column
const NPS_COL_TERMS = ['nps', 'promoter', 'score', 'rate', 'recommend']
const CSAT_COL_TERMS = ['csat', 'satisfaction', 'satisfied', 'rate', 'score']

const CHART_SUPPORTED_DOWNLOAD_FORMATS = ['png', 'svg']

const PNG = 'png'
const SVG = 'svg'

const PROJECT_LIST_FILTERS_DEFAULT = {
  search: '',
  archived: false,
  tab: {
    name: 'All projects',
    type: 'filter',
    value: 'all_projects'
  },
  pagination: {
    rowsPerPage: 10,
    page: 1
  },
  labels: [],
  owner: [],
  created: [],
  last_modified: [],
  language: [],
  data_source_provider: [],
  order_by: null
}

const CODING_FILTERS_DEFAULT = {
  search: ''
}

const FILTER_OPTIONS_TYPES = {
  STANDARD_FILTERS: 0,
  DASHBOARD_FILTERS: 1,
  DATASET_FILTERS: 2,
  CODING_FILTERS: 3,
  STANDARD_FILTERS_WITH_TOPICS: 4
}

const DATE_AGG_ENUM = {
  YEAR: 'YEAR',
  QUARTER: 'QUARTER',
  MONTH: 'MONTH',
  DAY: 'DAY'
}

const SENTIMENT_RANGE = [
  'negative', 'neutral', 'positive'
]
const SENTIMENT_RANGE_FULL = [
  'none',
  ...SENTIMENT_RANGE
]
const MAX_DASHBOARD_ITEMS_COUNT = 140

const MAX_PROJECT_ROWS_COUNT_FOR_FAST_APPEND = 100000

// WIZARD SUGGESTIONS
const REFINE_CATEGORIES = 'reassign'
const GENERATE_TOPICS = 'generate_topics'
const DELETE_TOPIC = 'delete_topic'
const ADDED_TOPICS = 'added_topics'
const OTHER_CATEGORY = 'review_topic'
const MOVED_TOPIC = 'moved_topic'

export {
  MEETING_LINK,

  PROJECT_LIST_FILTERS_DEFAULT,
  CODING_FILTERS_DEFAULT,

  MIN_TOPIC_LABEL_LEN,
  MAX_TOPIC_LABEL_LEN,
  MIN_TOPIC_CATEGORY_LEN,
  MAX_TOPIC_CATEGORY_LEN,
  MAX_TOPIC_DESCRIPTION_LEN,
  MAX_TOPIC_KEYWORD_LEN,
  MAX_ADD_KEYWORDS,

  TOPIC_ATTITUDES,
  TOPIC_ATTITUDE_TEMPLATE,
  TOPIC_TEMPLATE,
  TOPIC_ADHOC_ID_PREFIX,

  MIN_PROJECT_NAME_LEN,
  MIN_QUESTION_NAME_LEN,
  MAX_QUESTION_NAME_LEN,
  MIN_QUESTION_DESCRIPTION_LEN,

  MAX_EXCEL_EXPORT_ROWS,

  ADDITIONAL_COLUMNS,
  ADDITIONAL_COLUMNS_CODES,

  QUESTION_CATEGORIES,
  QUESTION_CATEGORY_CUSTOMER_SATISFACTION,
  QUESTION_CATEGORY_EMPLOYEE_FEEDBACK,
  QUESTION_CATEGORY_BRAND_PERCEPTION,
  QUESTION_CATEGORY_PRODUCT_PERCEPTION,
  QUESTION_CATEGORY_EVENT_EVALUATION,
  QUESTION_CATEGORY_LIST,
  QUESTION_CATEGORY_OTHER,
  QUESTION_CATEGORY_NONE,

  COLUMN_TYPE_QUESTION,
  COLUMN_TYPE_AUXILIARY,
  COLUMN_TYPE_DATE,
  COLUMN_TYPE_NULL,
  COLUMN_TYPE_NPS_10,
  COLUMN_TYPE_NPS_100,
  COLUMN_TYPE_LANGUAGE,
  COLUMN_TYPE_STAR,
  SELECTABLE_COLUMN_TYPES,

  TEXT_TO_ANALYZE_CATEGORY_CUSTOMER_SATISFACTION,
  TEXT_TO_ANALYZE_CATEGORY_EMPLOYEE_FEEDBACK,
  TEXT_TO_ANALYZE_CATEGORY_BRAND_PERCEPTION,
  TEXT_TO_ANALYZE_CATEGORY_PRODUCT_PERCEPTION,
  TEXT_TO_ANALYZE_CATEGORY_EVENT_EVALUATION,
  TEXT_TO_ANALYZE_CATEGORY_LIST,
  TEXT_TO_ANALYZE_CATEGORY_OTHER,
  TEXT_TO_ANALYZE_CATEGORY_NONE,
  TEXT_TO_ANALYZE_CATEGORIES,

  DEFAULT_INTEGRATION_LINK,

  PROJECT_NAME_DEMO_OPEN,
  PROJECT_NAME_DEMO_LIST,
  DASHBOARD_NAME_DEMO,
  CHART_NAME_DEMO,

  CHART_TYPES,
  CHART_TYPES_REAL,
  CHART_TYPE_BAR,
  CHART_TYPE_SENTIMENT_BAR,
  CHART_TYPE_LINE_PIE,
  CHART_TYPE_DATE_LINE_PIE,
  CHART_TYPE_ROWS_COUNT_BAR,
  CHART_TYPE_TREEMAP,
  CHART_TYPE_GRAPH,
  CHART_TYPE_SCORE,
  CHART_TYPE_DRIVER_SCATTER,
  CHART_TYPE_DRIVER_HEAT,
  CHART_TYPE_PSEUDO_TEXT,
  CHART_TYPE_PSEUDO_VERBATIMS,
  CHART_TYPE_DYNAMIC_BAR,

  CHART_UNIQUE_PSEUDO_TEXT,
  CHART_UNIQUE_PSEUDO_VERBATIMS,
  CHART_UNIQUE_NAMES,
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_DRIVER_SCATTER,
  CHART_UNIQUE_DRIVER_HEAT,
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_DYNAMIC_BAR,

  CHART_SUPPORTED_DOWNLOAD_FORMATS,
  CHART_TYPES_TO_API_ENUM,
  API_ENUM_TO_CHART_TYPES,
  SCORE_CHARTS,
  CODE_CHARTS,
  OVERVIEW_CHARTS,
  DS_FILTERS_TEMPLATE,
  DS_SETTINGS_TEMPLATE,
  USER_SETTINGS_TEMPLATE,

  NPS_COL_TERMS,
  CSAT_COL_TERMS,
  NPS_TERMS,
  CSAT_TERMS,

  SCORING_TYPE_NPS,
  SCORING_TYPE_CSAT,
  SCORING_TYPE_AVERAGE,
  SCORING_TYPE_SENTIMENT,

  SATISFACTION_SCORING_TYPES,
  UNIQUE_SCORING_TYPES,
  UNIQUE_CHARTS_FOR_UNIQUE_SCORING_TYPES,

  UPLOAD_ENCODING_OPTIONS,
  UPLOAD_ANSWERS_TRANSLATE_BILL_THRESHOLD,
  TRAINING_TIME_ESTIMATOR_BERT,

  PLAN_FREE,
  PLAN_TRIAL,
  PLAN_ONEOFF,
  PLANS_ACTIVE,
  STANDARD_PLAN_FEATURES,
  CUSTOM_PLAN_FEATURES,
  ONE_OFF_STEPS,

  BADGES,

  PNG,
  SVG,

  VERBATIM_PAGE_SIZE,
  INTEGRATION_CONSISTENCY_LIMIT_COUNT,

  FILTER_OPTIONS_TYPES,
  DATE_AGG_ENUM,
  SENTIMENT_RANGE,
  SENTIMENT_RANGE_FULL,

  DEFAULT_ROW_LIMIT,

  REFINE_CATEGORIES,
  GENERATE_TOPICS,
  DELETE_TOPIC,
  ADDED_TOPICS,
  OTHER_CATEGORY,
  MOVED_TOPIC,

  MAX_PROJECT_ROWS_COUNT_FOR_FAST_APPEND,
  MAX_DASHBOARD_ITEMS_COUNT,

  MAX_DATASET_COUNT
}
